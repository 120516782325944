import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import i18nText from './i18n/text';
import getLocaleString from './i18n/functions';
import App from './App.vue';
import store from './store';

const i18n = createI18n({
  locale: getLocaleString(),
  fallbackLocale: 'en',
  messages: i18nText,
});

createApp(App).use(store).use(i18n).mount('#app');

<template>
  <header>
    <details class="language-selector">
      <summary>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.4 49.212" width="58.4" height="49.212"><path d="M28.53 16.09a7.06 7.06 0 00-4.79 1.87 1.48 1.48 0 00-.94-.35 8.13 8.13 0 01-3.11-.59 12.28 12.28 0 002.48-6.3h.66a1.5 1.5 0 000-3h-4.29V5.39a1.5 1.5 0 10-3 0v2.34h-4.26a1.5 1.5 0 000 3h7.84a9 9 0 01-1.92 4.55 9.32 9.32 0 01-1.76-2.93 1.5 1.5 0 10-2.82 1 12.38 12.38 0 002.11 3.63 9.28 9.28 0 01-3.42.6 1.5 1.5 0 100 3 11.3 11.3 0 005.89-1.43 10.42 10.42 0 004.71 1.46 7 7 0 00-.51 2.63v1.12H10.97a1.5 1.5 0 00-1.14.52 1.47 1.47 0 00-.34 1.21c0 .1.19 1.23.53 2.82a1 1 0 01-1.57 1c-3.15-2.3-6.51-5.1-7.56-7.07a1.82 1.82 0 00-.15-.23A4.07 4.07 0 010 20.26V4.13A4.13 4.13 0 014.13 0h25.74A4.13 4.13 0 0134 4.13v12zm29.87 7.13v16.09a4 4 0 01-.74 2.35 1.17 1.17 0 00-.15.27c-1 2-4.41 4.77-7.56 7.09a1 1 0 01-1.57-1c.34-1.6.52-2.73.53-2.83a1.49 1.49 0 00-1.48-1.73h-18.9a4.13 4.13 0 01-4.13-4.15V23.22a4.13 4.13 0 014.13-4.13h25.74a4.13 4.13 0 014.13 4.13zm-10.42 14l-1.73-4.31-3.42-8.56a1.3 1.3 0 00-.08-.17l-.06-.1-.09-.12-.09-.1-.1-.09-.13-.1-.08-.06-.16-.09a.38.38 0 00-.1 0l-.16-.05h-.58l-.17.05a.38.38 0 00-.1 0l-.15.09-.11.05-.12.1-.1.09-.09.1-.1.12s0 .07-.05.1l-.09.17-3.5 8.57-1.73 4.32a1.51 1.51 0 00.84 2 1.55 1.55 0 00.56.11 1.51 1.51 0 001.39-1l1.34-3.35h4.84l1.34 3.35a1.49 1.49 0 001.39 1 1.54 1.54 0 00.55-.11 1.5 1.5 0 00.84-2zm-7.78-5.23h2.44l-1.22-3z"/></svg>
      </summary>
      <ul>
        <li v-for="(item, index) in Object.keys(languages)" :key="index">
          <a :href="`/${item}`">
            {{ languages[item].name }}
          </a>
        </li>
      </ul>
    </details>
  </header>
</template>

<script>
import languageText from '../i18n/text';

export default {
  name: 'Header',

  data() {
    return {
      languages: languageText,
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  text-align: left;
}

a {
  &:link, &:visited {
    border: none;
    text-decoration: none;
  }
  &:hover, &:focus, &:active {
    border: none;
    text-decoration: none;
  }
}

.language-selector {
  position: absolute;
  top: 10px;
  left: 10px;
}

  details {
    ul {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      border: 2px solid #1a6fc3;
      margin: 0;

      background-color: white;

      text-align: left;
      list-style: none;

      transform: translateY(0);
      transition: transform 0.3s 0s ease-out;

      li {
        padding-top: 8px;
        padding-right: 10px;
        padding-bottom: 8px;
        padding-left: 10px;
        border-bottom: 1px solid #1a6fc3;

        display: block;

        cursor: pointer;

        &:last-child {
          border: none;
        }

        &:hover, &:focus {
          background-color: #1a6fc3;
          color: white;
        }
      }
    }
  }
    summary {
      list-style: none;
    }

svg {
  width: 30px;
  height: auto;

  cursor: pointer;

  path {
    fill: #1a6fc3;
  }
}

@media screen and (min-width: 900px) {
  .language-selector {
    top: 20px;
    left: 20px;
  }

  svg {
    width: 45px;
  }
}
</style>

export default {
  en: {
    name: 'English',
    homepage: {
      headline: 'Pace Calculator',
      infotext: 'Set distance and expected time and let calculate pace and speed.',
      distance: 'Distance',
      time: 'Time',
      pace: 'Pace',
      speed: 'Speed',
      marathon: 'Marathon',
      halfMarathon: 'Half marathon',
      imprint: 'Imprint',
    },
  },
  de: {
    name: 'Deutsch',
    homepage: {
      headline: 'Pace Calculator',
      infotext: 'Gebe Distanz und erwartete Zeit vor und lasse Pace und Geschwindigkeit berechnen. ',
      distance: 'Distanz',
      time: 'Zeit',
      pace: 'Pace',
      speed: 'Geschwindigkeit',
      marathon: 'Marathon',
      halfMarathon: 'Halbmarathon',
      imprint: 'Impressum',
    },
  },
  pl: {
    name: 'Polski',
    homepage: {
      headline: 'Pace Calculator',
      infotext: 'Ustaw dystans i przewidywany czas oraz oblicz tempo i prędkość.',
      distance: 'Odległość',
      time: 'Czas',
      pace: 'Pace',
      speed: 'Prędkość',
      marathon: 'Maraton',
      halfMaraton: 'Półmaraton',
      imprint: 'Nadruk',
    },
  },
};

<template>
  <h2>Rekorde</h2>
  <form method="post" action="/">
    <div class="row">
      <div class="col-4">
        <label for="region">Region</label>
        <select id="region" v-model="region">
          <option value="world">Welt</option>
        </select>
      </div>
      <div class="col-4">
        <label for="recordsDistance">Distanz</label>
        <select id="recordsDistance" v-model="distance">
          <option value="5000">5{{localDecimalChar}}00km</option>
          <option value="10000">10{{localDecimalChar}}00km</option>
          <option value="halfmarathon">Halbmarathon</option>
          <option value="marathon">Marathon</option>
        </select>
      </div>
      <div class="col-4">
        <label for="gender">Geschlecht</label>
        <select id="gender" v-model="gender">
          <option value="male">Männer</option>
          <option value="female">Frauen</option>
        </select>
      </div>
    </div>
  </form>
  <template
    v-if="recordsData[distance] && recordsData[distance][gender][region]"
  >
    <table>
      <thead>
        <tr>
          <th>Zeit</th>
          <th>Pace</th>
          <th>Ø km/h</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="item in recordsData[distance][gender][region]"
          :key="`${distance}${gender}${region}${item.time}`">
          <tr>
            <td>{{item.time}}</td>
            <td>{{item.pace}}</td>
            <td>{{item.kmh}}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </template>
</template>

<script>
export default {
  name: 'RecordsTable',
  setup() {
    const localDecimalChar = 5.01.toLocaleString('de-DE').substr(1, 1);

    return {
      localDecimalChar,
    };
  },
  mounted() {
    fetch('/data/records.json').then(async (res) => {
      const data = await res.json();
      this.recordsData = data;
    });
  },
  data() {
    return {
      distance: '5000',
      region: 'world',
      gender: 'male',
      recordsData: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-left: -20px;
  display: flex;
}

  [class^="col-"] {
    padding-left: 20px;
  }
  .col-3 {
    flex-basis: 25%;
  }

  .col-4 {
    flex-basis: 33.33%;
  }

select {
  width: 100%;
  display: block;
}
</style>

import text from './text';

function setPath(language) {
  const url = new URL(window.location);
  url.pathname = `/${language}`;
  window.history.pushState({}, document.title, url);
  return language;
}

function getLocaleString() {
  const availableLanguages = Object.keys(text);
  const defaultLanguage = 'en';
  let path = '';
  const pathnameMatch = window.location.pathname.match(/^\/[a-zA-Z]*\/?/);
  if (pathnameMatch[0]) path = pathnameMatch[0].replace('/', '');
  if (availableLanguages.indexOf(path) > -1) return path;

  let browserLanguage = navigator.language || navigator.userLanguage;
  browserLanguage = browserLanguage.split('-');
  if (availableLanguages.indexOf(browserLanguage[0]) > -1) {
    return setPath(browserLanguage[0]);
  }

  return setPath(defaultLanguage);
}

export default getLocaleString;

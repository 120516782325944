<template>
  <footer>
    <ul>
      <li><a href="/impressum.html">{{ $t("homepage.imprint") }}</a></li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
footer {
  text-align: left;
}
</style>

<template>
  <div :class="['app-content', { 'is-mobile': isMobile }]">
    <Header/>

    <div class="text-container">
      <h1>{{ $t("homepage.headline") }}</h1>
      <p style="text-align:center">
        {{ $t("homepage.infotext") }}
      </p>
    </div>
    <div class="form-container">
      <DataForm />
    </div>
    <div class="text-container">
      <RecordsTable />
    </div>
  </div>

  <Footer/>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import DataForm from './components/DataForm.vue';
import RecordsTable from './components/RecordsTable.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    DataForm,
    RecordsTable,
    Header,
    Footer,
  },
  data() {
    return {
      isMobile,
    };
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'KumarOne';
  src: url('./assets/fonts/KumarOne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

body {
  background-image: url('./assets/images/marathon-berlin-runners.jpg');
}

h1,
h2 {
  margin-top: 0;
  margin-bottom: 20px;

  text-align: center;
}

p {
  margin: 0;
  margin-bottom: 20px;

  line-height: 1.5;
  font-weight: bold;

  &:last-child {
    margin-bottom: 0;
  }
}

table {
  width: 100%;
}

thead th {
  border-bottom: 1px solid currentColor;
}

.text-container {
  margin-bottom: 40px;

  text-align: left;
}

#app {
  padding-top: 20px;

  text-align: center;
}
</style>

<template>
  <form method="post" action="/">
    <div class="input-col">
      <label for="distance">{{ $t("homepage.distance") }} (km)</label>
      <div class="input-group  -distance  pos-relative">
        <input
          v-model="distance"
          @focus="selectValue"
          @change="handleFloatChange($event, 0.01, 999.99)"
          @keydown="updownFloat($event, 0.01, 999.99)"
          ref="distance"
          id="distance"
          type="text"
          inputmode="decimal"
          class="size-m"
        >

        <label
          @click="toggleSelectbox"
          ref="selectboxLabel"
          for="predefinedDistances"
          class="inline-block  arrow-down  js-selectboxtrigger"
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
        </label>

        <div class="selectbox-wrapper  no-pointer" ref="selectboxwrapper">
          <ol class="selectbox  is-hidden  js-selectboxtrigger" ref="selectbox">
            <li
              @click="handleFakeSelect"
              data-value="5.00"
            >5{{localDecimalChar}}00 km</li>
            <li
              @click="handleFakeSelect"
              data-value="10.00"
            >10{{localDecimalChar}}00 km</li>
            <li
              @click="handleFakeSelect($event, 4)"
              data-value="21.0975"
            >{{ $t("homepage.halfMarathon") }}</li>
            <li
              @click="handleFakeSelect($event, 3)"
              data-value="42.195"
            >{{ $t("homepage.marathon") }}</li>
          </ol>
        </div>

        <select
          @change="setDistance"
          ref="nativeSelect"
          id="predefinedDistances"
          class="select"
        >
          <option value="5.00">5{{localDecimalChar}}00 km</option>
          <option value="10.00">10{{localDecimalChar}}00 km</option>
          <option value="21.0975">{{ $t("homepage.halfMarathon") }}</option>
          <option value="42.195">{{ $t("homepage.marathon") }}</option>
        </select>
      </div>
    </div>

    <div class="input-col  mr-0  mb-0">
      <label for="hours">{{ $t("homepage.time") }} (hh:mm:ss)</label>
      <div class="input-group">
        <input
          v-model="hours"
          @focus="selectValue"
          @keydown="updownNumber($event, 0, 99)"
          @change="formatNumber($event, 0, 99)"
          id="hours"
          type="text"
          inputmode="numeric"
          class="size-s"
        >
        <span>:</span>
        <input
          v-model="minutes"
          @focus="selectValue"
          @keydown="updownNumber($event, 0, 59)"
          @change="formatNumber($event, 0, 59)"
          ref="minutesInput"
          id="minutes"
          type="text"
          inputmode="numeric"
          class="size-s"
        >
        <span>:</span>
        <input
          v-model="seconds"
          @focus="selectValue"
          @keydown="updownNumber($event, 0, 59)"
          @change="formatNumber($event, 0, 59)"
          id="seconds"
          type="text"
          inputmode="numeric"
          class="size-s"
        >
      </div>
    </div>

    <svg class="w-6 h-6  arrow-right" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>

    <div class="input-col  -readonly">
      <label for="pace">{{ $t("homepage.pace") }} (m/km)</label>
      <div class="input-group">
        <input
          v-model="pace"
          ref="pace"
          id="pace"
          type="text"
          name="pace"
          class="size-m"
          readonly
        >
      </div>
    </div>

    <div class="input-col  -readonly">
      <label for="kmh">Ø {{ $t("homepage.speed") }} (km/h)</label>
      <div class="input-group">
        <input
          v-model="kmh"
          ref="kmh"
          id="kmh"
          type="text"
          name="kmh"
          class="size-m"
          readonly
        >
      </div>
    </div>
    <span class="input-check" ref="check"></span>
  </form>
</template>

<script>
// import { onMounted } from 'vue';
// import FlexIntInput from './FlexIntInput.vue';

export default {
  name: 'DataForm',
  setup() {
    const localDecimalChar = 5.01.toLocaleString('de-DE').substr(1, 1);
    return {
      localDecimalChar,
    };
  },
  mounted() {
    setTimeout(() => {
      this.setInputSize(this.$refs.distance);
    }, 200);
    setTimeout(() => {
      this.setInputSize(this.$refs.distance);
    }, 1000);
  },
  methods: {
    toggleSelectbox() {
      this.$refs.selectbox.classList.toggle('is-hidden');
      this.$refs.selectboxwrapper.classList.toggle('no-pointer');
      this.$refs.selectboxLabel.classList.toggle('rotate-180');
      if (!this.$refs.selectbox.classList.contains('is-hidden')) {
        this.showDropdown(this.$refs.nativeSelect);
      }
    },

    formatNumber(event, min, max) {
      const input = event.target.getAttribute('id');
      let value = Number.parseInt(this[input], 10);
      if (Number.isNaN(value)) value = 0;
      if (value < min) value = min;
      if (value > max) value = max;
      value = `${value}`;
      if (value.length === 1) value = `0${value}`;

      this[input] = value;
    },

    handleFloatChange(event, min, max, defaultValue = 5, precision = 2) {
      const input = event.target.getAttribute('id');
      let value = Number.parseFloat(this[input].replace(',', '.'), 10);
      if (Number.isNaN(value)) value = defaultValue;
      if (value < min) value = min;
      if (value > max) value = max;
      value = this.formatFloat(value, precision);
      this[input] = value;
      this.$refs.nativeSelect.value = null;
      this.$nextTick(() => this.setInputSize(this.$refs.distance));
    },

    formatFloat(number, precision = 2) {
      const outputNumber = Number.parseFloat(`${number}`.replace(',', '.'), 10);
      return outputNumber.toFixed(precision).replace('.', this.localDecimalChar);
    },

    updownNumber(event, min, max, step = 1, length = 2) {
      const input = event.target.getAttribute('id');
      let value = Number.parseInt(this[input], 10);
      if (event.keyCode !== 38 && event.keyCode !== 40) return;
      if (event.keyCode === 38) {
        if (value < max) value += step;
        else value = this.getUpperMaxValue(input, value);
      } else if (event.keyCode === 40) {
        if (value > min) value -= step;
        else value = this.getLowerMinValue(input, value);
      }
      value = `${value}`;
      while (value.length < length) value = `0${value}`;
      this[input] = value;
    },

    getLowerMinValue(inputID, value, length = 2) {
      if (inputID === 'seconds') {
        if (Number.parseInt(this.minutes, 10) > 0) {
          let minutes = `${Number.parseInt(this.minutes, 10) - 1}`;
          while (minutes.length < length) minutes = `0${minutes}`;
          this.minutes = minutes;
          return 59;
        }
        if (Number.parseInt(this.hours, 10) > 0) {
          let hours = `${Number.parseInt(this.hours, 10) - 1}`;
          while (hours.length < length) hours = `0${hours}`;
          this.hours = hours;
          this.minutes = '59';
          return 59;
        }
      } else if (inputID === 'minutes') {
        if (Number.parseInt(this.hours, 10) > 0) {
          let hours = `${Number.parseInt(this.hours, 10) - 1}`;
          while (hours.length < length) hours = `0${hours}`;
          this.hours = hours;
          return 59;
        }
      }

      return value;
    },

    getUpperMaxValue(inputID, value, length = 2) {
      if (inputID === 'seconds') {
        if (Number.parseInt(this.minutes, 10) < 59) {
          let minutes = `${Number.parseInt(this.minutes, 10) + 1}`;
          while (minutes.length < length) minutes = `0${minutes}`;
          this.minutes = minutes;
          return 0;
        }
        if (Number.parseInt(this.hours, 10) < 99) {
          let hours = `${Number.parseInt(this.hours, 10) + 1}`;
          while (hours.length < length) hours = `0${hours}`;
          this.hours = hours;
          this.minutes = '00';
          return 0;
        }
      } else if (inputID === 'minutes') {
        if (Number.parseInt(this.hours, 10) < 99) {
          let hours = `${Number.parseInt(this.hours, 10) + 1}`;
          while (hours.length < length) hours = `0${hours}`;
          this.hours = hours;
          return 0;
        }
      }

      return value;
    },

    updownFloat(event, min, max, step = 0.01, precision = 2) {
      const input = event.target.getAttribute('id');
      let value = Number.parseFloat(this[input].replace(',', '.'), 10);
      if (event.keyCode !== 38 && event.keyCode !== 40) return;
      if (event.keyCode === 38) {
        if (value < max) value += step;
      } else if (event.keyCode === 40) {
        if (value > min) value -= step;
      }
      value = value.toFixed(precision);
      this[input] = value.replace('.', this.localDecimalChar);
    },

    handleFakeSelect(event, precision) {
      const item = event.target;
      const value = item.getAttribute('data-value');
      this.distance = this.formatFloat(value, precision);
      this.$nextTick(() => this.setInputSize(this.$refs.distance));
      this.toggleSelectbox();
    },

    selectValue(event) {
      event.target.select();
    },

    setDecimal(event, length) {
      const input = event.target.getAttribute('id');
      const values = event.target.value.split('.');
      let decimal = values[1] ? values[1] : '';
      if (decimal.length < length) {
        while (decimal.length < length) {
          decimal = `${decimal}0`;
        }
      } else if (decimal.length > length) {
        decimal = decimal.substr(0, length);
      }

      this[input] = `${values[0]}.${decimal}`;
    },

    setInputSize(target) {
      const input = target;
      this.$refs.check.innerHTML = input.value;
      input.style.width = `${this.$refs.check.offsetWidth}px`;
    },

    setDistance(event) {
      const selectInput = event.target;
      let precision = 2;
      if (selectInput.value === '21.0975') precision = 4;
      else if (selectInput.value === '42.195') precision = 3;
      this.distance = this.formatFloat(selectInput.value, precision);
      this.$nextTick(() => this.setInputSize(this.$refs.distance));
    },

    showDropdown(element) {
      const event = document.createEvent('MouseEvents');
      event.initMouseEvent('mousedown', true, true, window);
      element.dispatchEvent(event);
    },
  },
  data() {
    return {
      distance: `5${this.localDecimalChar}00`,
      hours: '00',
      minutes: '25',
      seconds: '00',
    };
  },
  computed: {
    pace() {
      const distance = Number.parseFloat(this.distance.replace(',', '.'), 10) * 1000;
      let hours = Number.parseInt(this.hours, 10);
      let minutes = Number.parseInt(this.minutes, 10);
      let seconds = Number.parseInt(this.seconds, 10);
      if (Number.isNaN(hours)) hours = 0;
      if (Number.isNaN(minutes)) minutes = 0;
      if (Number.isNaN(seconds)) seconds = 0;
      const time = (hours * 60 * 60) + (minutes * 60) + seconds;

      this.$nextTick(() => {
        this.setInputSize(this.$refs.pace);
      });

      if (distance && time) {
        const secondsPerKm = Math.round((time / distance) * 1000);
        const minutesPerKm = secondsPerKm / 60;
        const minutesPerKmSplit = `${minutesPerKm}`.split('.');
        let paceSeconds = '00';
        if (minutesPerKmSplit[1]) {
          paceSeconds = `${Math.round(
            parseFloat(`0.${minutesPerKmSplit[1]}`) * 60,
          )}`;
          if (paceSeconds.length === 1) {
            paceSeconds = `0${paceSeconds}`;
          }
        }

        if (paceSeconds === 60) {
          paceSeconds = '00';
          return `${minutesPerKmSplit[0] + 1}:${paceSeconds}`;
        }
        return `${minutesPerKmSplit[0]}:${paceSeconds}`;
      }

      return '';
    },

    kmh() {
      const distance = Number.parseFloat(this.distance.replace(',', '.'), 10) * 1000;
      let hours = Number.parseInt(this.hours, 10);
      let minutes = Number.parseInt(this.minutes, 10);
      let seconds = Number.parseInt(this.seconds, 10);
      if (Number.isNaN(hours)) hours = 0;
      if (Number.isNaN(minutes)) minutes = 0;
      if (Number.isNaN(seconds)) seconds = 0;
      const time = (hours * 60 * 60) + (minutes * 60) + seconds;

      this.$nextTick(() => {
        this.setInputSize(this.$refs.kmh);
      });
      if (distance && time) {
        const secondsPerKm = (time / distance) * 1000;
        const kmPerHour = 3600 / secondsPerKm;

        return `${kmPerHour.toFixed(2)}`.replace('.', this.localDecimalChar);
      }

      return '';
    },
  },

  components: {
    // FlexIntInput,
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
}

input {
  border: none;
  padding: 0;
  margin: 0;

  background: transparent;

  line-height: 1;
  font-family: 'KumarOne';
  font-weight: 400;
  font-size: 36px;
  color: inherit;

  cursor: pointer;
}
input::selection {
  background-color: transparent;
  color: white;
}

span {
  font-family: 'KumarOne';
  font-size: 36px;
}

input[readonly] {
  cursor: not-allowed;
}

input.size-s {
  width: 56px;

  text-align: center;
}

input.size-m {
  width: 115px;
}

.input-col {
  margin-bottom: 20px;
}

.input-col.-readonly {
  color: #323232;
}

.input-group {
  padding: 5px;

  display: inline-block;

  input {
    &:focus {
      outline: 0;
      border: none;
    }
  }
}

.input-check {
  border: 0;
  display: inline-block;
  clip: rect(0,0,0,0);
  position: absolute;
  top: -9000px;
  left: -9000px;
}

.inline-block {
  display: inline-block;
}

.pos-relative {
  position: relative;
}

.arrow-down {
  width: 32px;
  height: 32px;

  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.3s 0s ease-out;

  svg {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 3px;
    left: 0;

    g {
      fill: currentColor;
    }
  }
}

.arrow-right {
  width: 52px;
  height: 52px;

  g {
    fill: currentColor;
  }

  position: relative;
  top: -12px;
  left: 0;

  transform: rotate(90deg);
}

.select {
  position: absolute;
  top: -9000px;
  left: -9000px;
}

.selectbox {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: 2px solid #1a6fc3;
  margin: 0;

  background-color: white;

  text-align: left;
  list-style: none;

  transform: translateY(0);
  transition: transform 0.3s 0s ease-out;

  li {
    padding-top: 8px;
    padding-right: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    border-bottom: 1px solid #323232;

    display: block;

    cursor: pointer;

    &:last-child {
      border: none;
    }
  }
}
.selectbox.is-hidden {
  display: block;
  transform: translateY(-100%);
}

.selectbox-wrapper {
  width: 100%;

  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  transform: translateY(calc(100% - 20px));
}

.is-hidden {
  display: none;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media screen and (min-width: 900px) {
  .input-col {
    margin-right: 20px;

    display: inline-block;
  }

  .arrow-right {
    top: 12px;
    left: -4px;

    transform: none;
  }
}

.is-mobile {
  .input-group.-distance {
    input,
    label {
      position: relative;
      z-index: 1;
    }

    label {
      pointer-events: none;
    }
  }

  .selectbox-wrapper {
    display: none;
  }
  .select {
    width: 100%;
    display: block;
    position: absolute;
    top: 20%;
    right: 0;
    bottom: 20%;
    left: 0;
    opacity: 0;
  }
}

.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}

.no-pointer {
  pointer-events: none;
}
</style>
